<template>
  <div class="container">
    <div class="mt-4 mb-4">
      <div v-if="loading" class="d-flex justify-content-center mt-5 mb-5">
        <Preloader/>
      </div>

      <div v-else>
        <h4 class="text-center">Выпускная работа</h4>
        <div class="mt-3 mb-4" v-if="Object.keys(graduateWorkStudents.theme).length">

          <!-- info section -->
          <div class="card">
            <div class="card-body">
              <div>
                <p class="fs-5 mb-3">Информация о выпускной работе</p>
                <p>
                  <span class="badge bg-blue">KZ</span> {{graduateWorkStudents.theme?.theme_name_kz}}
                </p>
                <p>
                  <span class="badge bg-blue">RU</span> {{graduateWorkStudents.theme?.theme_name_ru}}
                </p>
                <p>
                  <span class="badge bg-blue">EN</span> {{graduateWorkStudents.theme?.theme_name_en}}
                </p>
                <p class="mt-3">
                  <span class="badge bg-blue"><i class="pi pi-user"></i></span>
                  {{graduateWorkStudents.theme?.last_name}} {{graduateWorkStudents.theme?.first_name}}
                  {{graduateWorkStudents.theme?.middle_name}}
                </p>
              </div>
            </div>
          </div>
          <!-- end info section -->

          <!-- chapter section -->
          <!--          <div-->
          <!--              v-for="(item,index) in graduateWorkStudents.chapterInfo.filter(i => i.to_date > dateNow() && i.from_date < dateNow())"-->
          <!--              :key="index">-->
          <div v-for="(item,index) in graduateWorkStudents.chapterInfo" :key="index">
            <Card class="mt-4 mb-4">
              <template #title>
                <div class="px-1">
                  <h5>{{ item.chapter_name }}</h5>
                  <vue-countdown :time="getCountdown(item.to_date)" v-slot="{ days, hours, minutes, seconds }">
                    Осталось: {{ days }} дней, {{ hours }} часов, {{ minutes }} минут, {{ seconds }} секунд.
                  </vue-countdown>
                </div>
              </template>

              <template #content>
                <div class="mt-2"
                     v-if="graduateWorkStudents.chapters.filter(i => i.graduate_work_chapter_id == item.id).length">

                  <!-- main comment section -->
                  <div
                      v-for="(filesItem, index) in graduateWorkStudents.chapters.filter(i=>i.graduate_work_chapter_id == item.id && i.parent_id == null)"
                      :key="index">

                    <div class="px-1">
                      <div class="fw-bolder text-blue">
                        {{filesItem.lastname}} {{filesItem.firstname}}
                      </div>
                      <div class="mt-2">
                        <p class="fs-5">{{filesItem.comment_text}}</p>
                        <p v-if="filesItem.file_url != null">
                          <a :href="'https://back.uib.kz/uploads/' + filesItem.file_url" class="mx-1">
                            Файл
                          </a>
                          <Button v-if="+filesItem.status===0"
                                  icon="pi pi-check"
                                  class="p-button-rounded p-button-outlined mx-1"
                                  @click="confirmStatus(filesItem.id)"/>
                          <Button v-else
                                  icon="pi pi-times"
                                  class="p-button-rounded p-button-danger p-button-outlined mx-1"
                                  @click="confirmStatus(filesItem.id)"/>
                        </p>
                      </div>
                      <div class="mt-2 fs-6">
                        {{convertGraduateWorkTimestampToDate(filesItem.created_at)}}
                      </div>

                      <div class="mt-2" v-if="filesItem.graduate_work_chapter_id == '4'">
                        <div v-if="graduateWorkStudents.plagiatResult.find(i=>i.graduate_work_comment_id == filesItem.id)?.plagiarism_result?.status == '6'">
                          <b>Процент плагиата: {{Math.round(parseFloat(graduateWorkStudents.plagiatResult.find(i=>i.graduate_work_comment_id == filesItem.id)?.plagiarism_result?.result))}}%</b>
                          <br>
                          <b>Процент уникальности: {{Math.round(parseFloat(graduateWorkStudents.plagiatResult.find(i=>i.graduate_work_comment_id == filesItem.id)?.percentage_of_uniqueness))}}%</b>
                          <br>
                          <a class="btn btn-primary" role="button" target="_blank"
                             :href="'https://plagiarism.uib.kz/strikeplagiarism/get_diploma_report.php?file_id='+graduateWorkStudents.plagiatResult
                             .find(i=>i.graduate_work_comment_id == filesItem.id)?.plagiarism_result?.id">Посмотреть отчет</a>


                        </div>
                        <div v-else>
                          <b>Результата еще нет</b>

                        </div>
                      </div>


                      <div class="d-flex flex-row-reverse">
                        <Button class="p-button-secondary" icon="pi pi-comment" label="Комментировать"
                                @click="openCommentSection(filesItem.id)"/>
                      </div>
                    </div>

                    <!-- create comment section -->
                    <div class="comment-section mt-4 mb-4" v-if="isCommentSectionVisible">
                      <div class="row">
                        <div class="col-md-6">
                          <input type="file" class="form-control"
                                 @change="handleFileUpload(item.id, filesItem.graduate_work_student, $event)">
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-6">
                        <textarea id="comment" class="form-control mb-2" rows="5"
                                  placeholder="Оставьте свой комментарий"
                                  @change="changeGraduateWorkChapterComment(item.id, filesItem.graduate_work_student, $event)">
                        </textarea>
                          <div class="d-flex flex-row-reverse">
                            <Button icon="pi pi-send" label="Отправить" @click="postComment()"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end create comment section -->

                    <hr>

                    <!-- child comment section -->
                    <div class="mt-2">
                      <div class="child-comment-section"
                           v-for="(childCommentItem, index) in graduateWorkStudents.chapters.filter(i=>i.parent_id == filesItem.id)"
                           :key="index">
                        <div class="card mt-2" :class="[childCommentItem.user_id!=getUserId?'':'bg-light']">
                          <div class="card-body">
                            <div v-if="childCommentItem.parent_id != null">
                              <div class="fw-bolder"
                                   :class="[childCommentItem.user_id!=getUserId?'text-blue':'text-orange']">
                                {{childCommentItem.lastname}} {{childCommentItem.firstname}}
                              </div>
                              <div class="mt-2">
                                <p class="fs-5">{{childCommentItem.comment_text}}</p>
                                <p v-if="childCommentItem.file_url != null">
                                  <a :href="'https://back.uib.kz/uploads/' + childCommentItem.file_url" class="mx-1">
                                    Файл
                                  </a>
                                  <template v-if="childCommentItem.user_id!=getUserId">
                                    <Button v-if="+childCommentItem.status===0"
                                            icon="pi pi-check"
                                            class="p-button-rounded p-button-outlined mx-1"
                                            @click="confirmStatus(childCommentItem.id)"/>
                                    <Button v-else
                                            icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-outlined mx-1"
                                            @click="confirmStatus(childCommentItem.id)"/>
                                  </template>
                                </p>
                              </div>
                              <div class="mt-2 fs-6">
                                {{convertGraduateWorkTimestampToDate(childCommentItem.comment_created_at)}}
                              </div>



                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end child comment section -->

                    <!-- create comment section | end create comment section -->

                  </div>
                  <!-- end main comment section -->

                </div>

              </template>
            </Card>

          </div>
          <!-- end chapter section -->

        </div>

        <div v-else class="text-center">
          Тема дипломной работы не выбрана
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
  import {convertGraduateWorkTimestampToDate} from "@/utils/helpers/convertGraduateWorkTimestampToDate"
  import {getCookie} from "@/utils/helpers/cookies.helpers";

  export default {
    name: "GraduateWork",
    data() {
      return {
        loading: true,
        isCommentSectionVisible: false,
        theme_id: +this.$route.query.theme_id || 0,
        newComment: {},
      }
    },
    computed: {
      ...mapState('graduateWorkStudents', ['graduateWorkStudents']),
      getUserId() {
        return getCookie('USER_ID')
      }
    },
    methods: {
      ...mapActions('graduateWorkStudents', ['GET_STUDENT_GRADUATE_WORK_THEME_BY_ID', 'GET_GRADUATE_WORK_CHAPTER_BY_THEME_ID',
        'GET_STUDENT_CHAPTERS_BY_STUDENT_ID', 'PUT_COMMENT_STATUS', 'POST_GRADUATE_WORK_COMMENT']),
      convertGraduateWorkTimestampToDate,
      openCommentSection(parent_id) {
        this.isCommentSectionVisible = this.isCommentSectionVisible !== true;
        this.clearNewComment()
        this.newComment.parent_id = parent_id
      },
      dateNow() {
        return Math.round((new Date()).getTime() / 1000);
      },
      changeGraduateWorkChapterComment(id, graduate_work_student_id, e) {
        this.newComment.comment = e.target.value
        this.newComment.graduate_work_chapter_id = id
        this.newComment.graduate_work_student = graduate_work_student_id
      },
      handleFileUpload(id, graduate_work_student_id, e) {
        this.newComment.file_url = e.target.files[0]
        this.newComment.graduate_work_chapter_id = id
        this.newComment.graduate_work_student = graduate_work_student_id
      },
      async postComment() {
        console.log(this.newComment, 'newComment')
        this.isCommentSectionVisible = false
        await this.POST_GRADUATE_WORK_COMMENT(this.newComment)
        await this.GET_STUDENT_CHAPTERS_BY_STUDENT_ID(this.graduateWorkStudents.theme.student_data_id)
        this.clearNewComment()
      },
      getFilteredGraduateWorkChapterFiles(graduate_work_chapter_id) {
        return this.graduateWorkStudents.chapters.filter(i => i.graduate_work_chapter_id == graduate_work_chapter_id)
      },
      async confirmStatus(id) {
        let comment = this.graduateWorkStudents.chapters.find(i => i.id === id)
        if (comment) {
          console.log(comment, 'comment')
          await this.PUT_COMMENT_STATUS({id: comment.id, status: +comment.status === 0 ? 1 : 0})
          await this.GET_STUDENT_CHAPTERS_BY_STUDENT_ID(this.graduateWorkStudents.theme.student_data_id)
        }
      },
      clearNewComment() {
        this.newComment = {}
      },
      getCountdown(unix_timestamp) {
        const deadline = new Date(unix_timestamp * 1000);
        const now = new Date();
        return deadline - now
      },

    },
    async mounted() {
      await this.GET_STUDENT_GRADUATE_WORK_THEME_BY_ID(this.theme_id)
      if (this.graduateWorkStudents.theme?.student_data_id) {
        await this.GET_GRADUATE_WORK_CHAPTER_BY_THEME_ID(this.theme_id)
        await this.GET_STUDENT_CHAPTERS_BY_STUDENT_ID(this.graduateWorkStudents.theme.student_data_id)
      }
      this.loading = false
    }
  }
</script>

<style scoped>
  .bg-blue {
    background-color: #3b8afd;
  }

  .text-blue {
    color: #3b8afd;
  }

  .text-orange {
    color: #fd9741;
  }
</style>