export function convertGraduateWorkTimestampToDate(timestamp) {
    let date = new Date(timestamp * 1000)
    let month = date.getMonth() + 1
    let day = date.getDate()
    let hour = date.getHours()
    let minute = date.getMinutes()
    let monthZero = month < 10 ? '0' : ''
    let dayZero = day < 10 ? '0' : ''
    let hourZero = hour < 10 ? '0' : ''
    let minuteZero = minute < 10 ? '0' : ''
    return  dayZero + day + '.' + monthZero + month + '.' + date.getFullYear() + ' ' + hourZero + hour + ":" + minuteZero + minute

}